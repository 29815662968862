.App {
  
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.button-container2 {

display:grid;
align-items:center;
justify-items:center;


}
.gradient-background {
  background: linear-gradient(to bottom, #6cc1fa, #5286a5); /* Example gradient colors */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gradient-background2 {
  background: linear-gradient(to bottom, #58bcff, #1f5879); /* Example gradient colors */
  width: 100%;
  height: 100vh;
  
  justify-content: center;
  align-items: center;
}

.container {
  box-shadow: 22px 5px 11px 3px #002d57;
  width: 70%; /* Adjust this value to your preference */
  height: 70vh; /* Adjust this value to your preference */
  max-width: 700px; /* Adjust this value to your preference */
  padding: 50px; /* Adjust this value to add padding from each direction */
  background-color: rgba(76, 143, 230, 0.349); /* Or any other background color you want */
  border-radius: 50px; /* Adjust this value for circular corners */
  margin-right: 40px;
  display: flex
}

.container2{
  width: 70%; /* Adjust this value to your preference */
  height: 70vh; /* Adjust this value to your preference */
  max-width: 800px; /* Adjust this value to your preference */
  padding: 50px; /* Adjust this value to add padding from each direction */
  background-color: rgba(76, 143, 230, 0.349); /* Or any other background color you want */
  border-radius: 50px; /* Adjust this value for circular corners */
  margin-right: 40px;
  display: flex
}

.TextColumn {
 
  margin-left: 60px;
  margin-right:40px;
}

.Header {
  
  background-color: #2372b3;
  max-width: 400px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.IconWidget {
justify-content: center;
  background-color: rgba(113, 175, 255, 0.685);
  border-radius: 50px;
  max-height: 200px;
  min-height: 50px;
  box-shadow: 12px 5px 11px 3px #002d57;
  max-width: 150px;
margin-top: 40px;
  display: flex;
 
}

.youtube-video {

  
  margin-left: 60px;
  min-width: 400px;
}

.arrow-button {
background-color: rgba(186, 238, 255, 0.363);
  border-radius: 10px;
  margin-left: 18px;
}

.chart-container {
  margin-right: 10px;
margin-top: 40px;

  background-color: rgba(113, 175, 255, 0.685);
  border-radius:40px;
  box-shadow: 12px 5px 11px 3px #002d57;
}

.icon-container2 {

  display: flex;




}

.row-container {

  display: flex;
  
}

.button-container {

align-items: center;
justify-items: center;

}

.buttonClass {
  font-size:15px;
  font-family:Arial;
  width:140px;
  height:50px;
  border-width:1px;
  align-items: center;
  height: 40px;
  justify-items: center;
  color:#fff;
  border-color:#4e6096;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
  box-shadow: 0px 0px 0px 2px #9fb4f2;
  text-shadow: 0px 1px 0px rgba(41, 58, 104, 1);
  background:linear-gradient(rgba(104, 156, 255, 1), rgba(8, 53, 109, 1));
}

.buttonClass:hover {
  background: linear-gradient(rgba(8, 53, 109, 1), rgba(104, 156, 255, 1));
}

.button {
  padding: 15px 30px;
  background-color: #67a4ffe0; /* Green background */
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top:16px;
  min-width: 200px;
}

.button:hover {
  background-color: #1e72aa; /* Darker green background on hover */
}